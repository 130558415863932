<template>
  <v-container class="resources mb-12">
    <h2>Helpful Resources</h2>
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-card hover color="rgba(0,55,103,0.2)">
          <v-list dense color="transparent">
            <div class="px-4">
              <h3>Phone / Text</h3>
            </div>
            <v-list-item
            v-for="(next, i) in crisisLine"
            :key="i"
            class=""
          >{{ next.text }}</v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-6">
        <v-card hover color="rgba(0,55,103,0.2)">
          <v-list dense color="transparent">
            <div class="px-4">
              <h3>Websites</h3>
            </div>
            <v-list-item>Additional information can be found at the following websites:</v-list-item>
            <v-list-item
            v-for="(next, i) in websites"
            :key="i"
            class=""
          ><a :href="next.url" target="_blank">{{ next.text }}</a></v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    crisisLine: [
      {
        text: "If you are in a crisis or emotional distress, please call:"
      },
      {
        text: "Com-Care Crisis: 316-660-7500",
      },
      {
        text: "National Suicide Hotline: 1-800-273-8255"
      },
      {
        text: "National Suicide Text: 741741"
      },
      {
        text: "National Domestic Violence Hotline 1-800-799-7233"
      },
      {
        text: "Wichita Area Sexual Assault Center (WASAC) 316-263-0185"
      }
    ],
    websites: [
      {
        text: "Mayo Clinic",
        url: "https://www.mayoclinic.org/"
      },
      {
        text: "Cleveland Clinic",
        url: "https://my.clevelandclinic.org/"
      },
      {
        text: "National Institute of Mental Health (NIMH)",
        url: "https://www.nimh.nih.gov/"
      }
    ]
  })
};
</script>
